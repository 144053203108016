.cardcontainer {
  justify-content: center;
  display: flex;
  align-items: center;
}

.custom-modal {
  width: 100%;
  height: 300px; /* Set maximum height to 80% of viewport height for screens smaller than 768px */
}

@media screen and (min-width: 768px) {
  .custom-modal {
    width: 100%;
    height: 700px; /* Set maximum height to 700px for screens larger than 768px */
  }
}

