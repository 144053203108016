

img {
  display: block;
  max-width: 100%; /* set the maximum width of the image */
  height: auto; /* maintain aspect ratio */
}

@media (max-width: 768px) {
  img {
    width: 100%; /* set the width to 100% on screens smaller than 768px */
  }
}