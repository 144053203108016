

.card {
  margin-top: 20px;
  margin-bottom: 20px;
}

.service1 {
  height: 100px;
  background-image: url("/src/Data/calls.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100px;
  margin-bottom: 20px;
}

.service2 {
  height: 100px;
  background-image: url("/src/Data/target.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 110px;
  margin-bottom: 20px;
}

.service3 {
  height: 120px;
  background-image: url("/src/Data/data.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 120px;

}

.main-content {
  flex: 1;
}

.company-content {
    font-family: 'Courier New', Courier, monospace;
}

.line-content {
    border-top: 1px;
    border-style: solid;
    border-color: lightgray;
    width: 100px;
    align-items: center;
    justify-content: center;
    display: flex;
    text-align: center;
}