.footer-content {
    background-color: lightgray;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    margin-top: 110px;
    text-align: center;
}



