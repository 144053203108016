header {
  background-color: white;
  color: black;
  height: 100px;
  font-size: 20px;
  border-bottom: 1px lightgray solid ;
}



.container {
  height: 100px;
 
}

#navItems {
  height: 100px;
}

.active {
  border-bottom: 1px solid black;
}

.logo {
  background-image: url("../Data/Oflunlogo.jpg");
  height: 100%; /* Adjust this to the desired height */
  width: 100%; 
  background-position: left;
  background-repeat: no-repeat;
  text-align: center;
}

.c-name {
  font-family:monospace
}


nav a {
  color: black;
  text-decoration: none;
  color: inherit;
  outline: 0;
  cursor: auto;
  margin-right: 3rem;
}

nav a:hover {
  border-bottom: 1px solid black;
  text-decoration: none;
  color: inherit;
  outline: 0;
  cursor: pointer;

}

a {
  text-decoration: none;
  color: inherit;
  outline: none;
}

.navbar-toggler-icon .icon-bar {
  background-color: #333;
  border-radius: 1px;
  display: block;
  height: 2px;
  width: 22px;
  transition: all 0.2s ease-in-out;
}



.dropdown-toggle::after {
  display: none !important;

}

.dropdown-menu a:hover {
  background-color: lightgray !important;
  cursor: pointer;
}