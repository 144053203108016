.App {
  text-align: center;
}

.App-header {
  background-color: black;
  min-height: 100px;

  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

